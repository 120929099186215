import * as THREE from 'three'

/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useEffect, useRef, useState } from "react";
import { useGLTF, useTexture } from "@react-three/drei";
import { useThree } from "@react-three/fiber";
import { useLocomotiveScroll } from 'react-locomotive-scroll';



export default function Model(props) {

  let currentScroll = 0
  let initialBrightness = 0.8

  // Load

  const group = useRef();
  const { nodes, materials } = useGLTF("/Garfield.gltf");


  // Textures

  const map = useTexture('./textures/diffuse.jpg')
  map.flipY = false
  map.magFilter = THREE.LinearFilter

  const roughness = useTexture('./textures/roughness.jpg')
  roughness.flipY = false
  roughness.magFilter = THREE.LinearFilter

  const lightmap = useTexture('./textures/lightmap.jpg')
  lightmap.flipY = false
  lightmap.magFilter = THREE.LinearFilter

  const video = document.getElementById('lofi-video');
  const videoTexture = new THREE.VideoTexture(video);
  videoTexture.encoding = THREE.sRGBEncoding


  // Materials
  
  const material = materials["Base Material"]

  material.map = map
  material.lightMap = lightmap
  material.lightMapIntensity = initialBrightness
  material.roughnessMap = roughness
  material.metalness = 0
  // material.needsUpdate = true


  const tvMaterial = new THREE.MeshBasicMaterial()

  tvMaterial.map = videoTexture
  tvMaterial.emissiveMap = videoTexture
  tvMaterial.side = THREE.FrontSide
  tvMaterial.toneMapped = false


  document.addEventListener('scroll', (e) => {
      if (props.scrollObject.isReady) {
        currentScroll = props.scrollObject.scroll.scroll.instance.scroll.y

        // Dim Lightmap
        material.lightMapIntensity = 0.8 - currentScroll / (window.innerHeight * 0.8)
      }
  })
  


  return (
    <group ref={group} {...props} dispose={null} >
      <mesh
        geometry={nodes.Garfield_Joined001.geometry}
        material={material}
      />

      <mesh 
        position={[-0.165,1.06,2.22]} 
        rotation={[0, Math.PI, 0]}
        material={tvMaterial}
        >
        <planeGeometry args={[1.055,0.6]} />
     </mesh>

    </group>
  );
}

useGLTF.preload("/Garfield.gltf");